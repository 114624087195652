import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useAuth } from "../src/contexts/AuthProvider";
import { routes } from "../src/routes";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "../src/components/Logo";
import { validateEmail } from "../src/utils/email";
import { validatePassword } from "../src/utils/password";
import {
  INVALID_EMAIL,
  INVALID_PASSWORD,
  USER_NOT_FOUND,
  WRONG_PASSWORD,
  BAD_CREDENTIALS
} from "../src/constants";

import { signIn } from "../src/services/auth";
import Head from "next/head";
import ReCAPTCHA from "react-google-recaptcha";
const useRecapcha = process.env.NODE_ENV !== "development";

export default function Login() {
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef();
  const router = useRouter();
  const { user, syncing } = useAuth();
  const [state, setState] = useState({
    email: "",
    password: "",
    errors: {},
    loading: false,
  });
  const [serverError, setServerError] = useState(null);
  useEffect(() => {
    if (user && !syncing) {
      if (user.isAdmin) {
        router.replace(routes.admin);
      } else {
        if (router && router.query && router.query.data) {
          router.replace(router.query.data);
        } else {
          router.replace(routes.home);
        }
      }
    }
  }, [user, syncing, router]);

  const handleInputChange = ({ target: { name, value } }) => {
    if (state.errors[name]) {
      delete state.errors[name];
    }
    setState({ ...state, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validateFormFields = (email, password) => {
    console.log("NODE ENV", process.env.NODE_ENV);
    if (useRecapcha && !captchaValue ) {
      setServerError("Please complete the reCAPTCHA.");
      return;
    }
    const validEmail = validateEmail(email);
    const validPassword = validatePassword(password);
    const errors = {};

    if (Object.keys(state.errors).length > 0) {
      setState((state) => ({ ...state, errors: {} }));
    }

    if (!validEmail) {
      errors.email = INVALID_EMAIL;
    }

    if (!validPassword) {
      errors.password = INVALID_PASSWORD;
    }

    if (Object.keys(errors).length > 0) {
      setState({ ...state, errors });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = state;
    const valid = validateFormFields(email, password);
    if (!valid) return;

    setServerError(null);
    try {
      setState((state) => ({ ...state, loading: true }));
      await signIn(email, password);
    } catch (error) {
      setState({ ...state, loading: false });
      if (error.code === USER_NOT_FOUND) {
        setServerError("This email is not registered!");
        return;
      }

      if (error.code === WRONG_PASSWORD) {
        setServerError("Wrong password!");
        return;
      }

      if (error.code === BAD_CREDENTIALS) {
        setServerError(
          "Wrong email or password."
        );
        return;
      }

      console.log(error);

      setServerError(error.message);
    }
  };

  const handleSignUp = () => {
    let state = {};
    if (router && router.query && router.query.data) {
      state.redirectTo = router.query.data;
    }
    router.push({
      pathname: routes.signUp,
      state,
    });
  };

  return (
    <>
      <Head>
        <title>Sign In | True Dating</title>
        <meta
          name="description"
          content="Login True Dating website and explore our premier speed dating and singles events service in London. Check out the special code to get 10% OFF for events."
        />
      </Head>
      <Grid
        container
        component={"main"}
        sx={(theme) => ({
          minHeight: "100vh",
          backgroundColor: theme.palette.grey[100],
        })}
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(/images/find-your-perfect-match.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          container
          xs={12}
          sm={8}
          md={5}
          sx={(theme) => ({ padding: theme.spacing(4) })}
        >
          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <Box
              sx={(theme) => ({
                maxWidth: 400,
                marginBottom: theme.spacing(4),
              })}
            >
              <Logo />
              <h1 style={{ display: "none" }}>Log In</h1>
            </Box>
            <Box maxWidth={500} width={"100%"}>
              <form
                onChange={handleInputChange}
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <Grid container spacing={2}>
                  {!!serverError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{serverError}</Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={!!state.errors.email}
                    helperText={state.errors.email || ""}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={!!state.errors.password}
                    helperText={state.errors.password || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  mt={2}
                >
                  <Link href={routes.passwordReset} underline="none">
                    Forgot Password?
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    {useRecapcha && <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                      onChange={handleCaptchaChange}
                    />}
                  </Box>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                  }}
                  disabled={state.loading}
                  endIcon={
                    state.loading ? <CircularProgress size={16} /> : undefined
                  }
                >
                  Sign In
                </Button>
                <Box textAlign="center" mt={2}>
                  <Typography component="span" variant="body2">
                    {`Don't have an account? `}
                  </Typography>
                  <Link
                    className="login_signupButton"
                    onClick={handleSignUp}
                    variant="body2"
                    underline="none"
                  >
                    {`Sign Up`}
                  </Link>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
